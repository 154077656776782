<template>
  <div class="adding-bank-container">
    <img class="visa-img" src="@/assets/img/visa_rupay.png" alt="" />
    <input
      class="adding-bank-input marginTop15"
      type="text"
      placeholder="卡号"
    />
    <input
      class="adding-bank-input marginTop10"
      type="text"
      placeholder="持卡人姓名"
    />
    <div class="flex sm:block marginTop16 h-6vh">
      <input
        class="adding-bank-select"
        type="text"
        placeholder="月"
      />
      <input
        class="adding-bank-select marginLeft10"
        type="text"
        placeholder="年"
      />
      <input class="adding-bank-cvv marginLeft10" type="text" placeholder="CVV" />
    </div>
  </div>
</template>

<script>
export default {

}

</script>

<style scoped>
.adding-bank-select {
  width: 102px;
  height: 48px;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding-left: 16px;
}
.adding-bank-cvv {
  width: 114px;
  height: 48px;
  background: #ffffff;
  border-radius: 2px;
  padding-left: 16px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.adding-bank-container {
  width: 540px;
  padding: 24px 50px 38px 50px;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 18px;
}
.adding-bank-input {
  display: block;
  width: 100%;
  height: 48px;
  padding-left: 16px;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.visa-img {
  width: 360px;
  height: 57px;
  display: block;
  margin: 0 auto;
}
@media only screen and (max-width:600px){
.adding-bank-input {
  display: block;
  width: 100%;
  height: 6vh;
  padding-left: 16px;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.adding-bank-container {
  width: 100%;
  padding: 3vw 5vw;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 18px;
}
.adding-bank-select {
  width: 20vw;
  height: 100%;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding-left: 16px;
}
.visa-img {
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}
.adding-bank-cvv{
  width: 20vw;
  height: 100%;
}
.marginTop16{
  margin-top: 1vh;
}
.marginTop15{
  margin-top: 1vh;
}
}
</style>
