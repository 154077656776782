<template>
  <div>
    <div class="top-bar pb-2vh">
      <div class="account-title">添加信用卡/借记卡</div>
    </div>
    <div class="bank-bot sm:flex flex-row-reverse block sm:flex-row">
      <div class="bank-bot-left">
        <AddingBank class="mx-auto shrink0"></AddingBank>
        <div class="font14 marginTop32">选择要存入的资金账户</div>
        <div class="add-card marginTop32">
          <div class="add-card-font">添加卡片</div>
        </div>
      </div>
      <div class="bank-explain">
        <div class="font18">卡片验证</div>
        <div class="bank-explain-text marginTop16">
          •
          我们将从您的卡中扣除小笔验证金额(最多您的卡币2欧元)。这可能需要0-3个工作日。
        </div>
        <div class="bank-explain-text marginTop16">
          • 如果您的卡已在3D
          Secure.上注册，则将自动验证。否则，请在线检查您的信用卡对帐单，或直接联系您的银行以获取准确的借方金额。
        </div>
        <div class="bank-explain-text marginTop16">
          •
          访问您的ecoAccount并在“银行账户和银行卡"中选择您银行卡条目下的"核验"。请输入货币和充值金额。
        </div>
        <div class="bank-explain-text marginTop16">
          • 您的卡将通过验证，并且所扣取的金额将记入您的ecoAccount。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddingBank from '@/components/User/AddingBank.vue'

export default {
  components: {
    AddingBank
  }
}
</script>

<style scoped>

.bank-bot {
  padding-top: 26px;
  padding-left: 26px;
  display: flex;
}
.add-card {
  width: 320px;
  height: 180px;
  border-radius: 16px;
  background: #ffffff url("~@/assets/icon/add.png") no-repeat 50% 42%/32px
    32px;
  position: relative;
}
.add-card-font {
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.25);
  line-height: 16px;
  position: absolute;
  top: calc( 56% );
  left: calc(40%);
}
.bank-explain-text {
  font-size: 12px;

  font-weight: 400;
  color: rgba(0, 0, 0, 0.45);
  line-height: 20px;
}
.bank-explain {
  margin-left: 65px;
  padding-top: 16px;
  width: 391px;
}
@media only screen and (max-width:600px){
.bank-bot {
  padding: 5vw;
  display: block;
}
.bank-explain {
  margin-left: 0px;
  padding-top: 5vw;
  width: 100%;
}
.add-card {
  width: 90%;
  margin: 0 auto;
  height: 25vh;
  border-radius: 16px;
  background: #ffffff url("~@/assets/icon/add.png") no-repeat 50% 40%/32px
    32px;
  position: relative;
}
.marginTop32{
  margin-top: 2.5vh;
}
}
</style>
